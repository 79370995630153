<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card>
          <div>
            <div class="d-flex">
              <v-select
                v-model="selectedZone"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="zone"
                @input="loadItems()"
                :clearable="true"
                class="w-25 my-1"
                :reduce="(zone) => zone.id"
                label="zone_name"
              >
                <template #option="option">
                  <span>{{ option.zone_name }}</span>
                </template>
              </v-select>
              <b-button
                variant="outline-success"
                class="d-flex align-items-center mx-1 my-1"
                v-b-modal.zone
              >
                <FeatherIcon icon="PlusIcon" />
                <span class="mx-1">Add New Zone</span>
              </b-button>
            </div>

            <b-row>
              <b-button
                variant="primary"
                class="d-flex align-items-center mx-1"
                v-b-modal.add
              >
                <FeatherIcon icon="PlusCircleIcon" />
                <span class="mx-1">Add New Price</span>
              </b-button>
              <b-button
                variant="primary"
                class="d-flex align-items-center"
                @click="extractPostcode()"
              >
                <FeatherIcon icon="ApertureIcon" />
                <span class="mx-1">Reload Table Data</span>
              </b-button>
            </b-row>
            <div class="d-flex my-2 align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <b-form-input
                  v-model="postcodeFrom"
                  placeholder="Postcode From"
                  type="number"
                />
                <h5 class="mx-1 d-flex align-items-center">to</h5>
                <b-form-input
                  v-model="postcodeTo"
                  placeholder="Postcode To"
                  type="number"
                />
                <b-input-group-append @click="extractPostcode">
                  <b-input-group-text class="bg-primary text-white ml-1">
                    Extract Postcode
                  </b-input-group-text>
                </b-input-group-append>
              </div>
            </div>
          </div>
          <vue-good-table
            :key="restart"
            style-class="vgt-table striped"
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :pagination-options="{
              enabled: true,
              dropdownAllowAll: false,
            }"
            :rows="rows"
            :columns="columns"
          >
            <div slot="emptystate" class="text-center">
              <span v-if="!isLoading">No Data Found</span>
              <b-spinner v-else variant="primary" />
            </div>
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field === 'selling_price'">
                {{ $helpers.formatTotal(props.row.selling_price) }}
              </div>

              <div v-else-if="props.column.field === 'selling_price_cost'">
                {{ $helpers.formatTotal(props.row.selling_price_cost) }}
              </div>

              <div v-else-if="props.column.field === 'double_charge'">
                {{ $helpers.formatTotal(props.row.double_charge) }}
              </div>

              <div v-else-if="props.column.field === 'double_charge_cost'">
                {{ $helpers.formatTotal(props.row.double_charge_cost) }}
              </div>

              <div v-else-if="props.column.field === 'convert_risk'">
                {{ $helpers.formatTotal(props.row.convert_risk) }}
              </div>

              <div v-else-if="props.column.field === 'actions'">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="editPrice(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="handleDelete(props.row)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>

              <div v-else>
                {{ props.formattedRow[props.column.field] }}
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>
    </b-row>
    <!-- ADD NEW PRICE MODAL -->
    <b-modal
      ref="add"
      id="add"
      title="Add New Price"
      hide-footer
      no-close-on-backdrop
    >
      <ValidationObserver ref="form">
        <form @submit.prevent="handleSubmit">
          <b-row>
            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="postcode_from"
                name="Start Postcode"
                rules="required"
              >
                <b-form-group label="Start Postcode">
                  <b-form-input v-model="form.postcode_from" type="number" />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="postcode_to"
                name="End Postcode"
                rules="required"
              >
                <b-form-group label="End Postcode">
                  <b-form-input v-model="form.postcode_to" type="number" />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="selling_price"
                name="Unit Price"
                rules="required"
              >
                <b-form-group label="Unit Price">
                  <b-form-input
                    v-model="form.selling_price"
                    type="number"
                    :min="1"
                  />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="selling_price_cost"
                name="Unit Price Cost"
                rules="required"
              >
                <b-form-group label="Unit Price Cost">
                  <b-form-input
                    v-model="form.selling_price_cost"
                    type="number"
                  />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="double_charge"
                name="Double Charged"
                rules="required"
              >
                <b-form-group label="Double Charged">
                  <b-form-input
                    v-model="form.double_charge"
                    type="number"
                    :min="1"
                  />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="double_charge_cost"
                name="Double Charged Cost"
                rules="required"
              >
                <b-form-group label="Double Charged Cost">
                  <b-form-input
                    v-model="form.double_charge_cost"
                    type="number"
                  />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row class="align-items-center justify-content-center">
            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="convert_risk"
                name="Convert Risk"
                rules="required"
              >
                <b-form-group label="Convert Risk">
                  <b-form-input v-model="form.convert_risk" />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="zone"
                name="Zone"
                rules="required"
              >
                <b-form-group label="Zone">
                  <v-select
                    v-model="form.zone"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="zone"
                    :clearable="true"
                    :reduce="(zone) => zone.id"
                    label="zone_name"
                  >
                    <template #option="option">
                      <span>{{ option.zone_name }}</span>
                    </template>
                  </v-select>
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <div class="d-flex align-items-center justify-content-center my-1">
            <b-button type="submit" variant="primary"> Submit </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
    <!-- EDIT PRICE MODAL -->
    <b-modal
      ref="edit"
      id="edit"
      title="Edit Price"
      hide-footer
      no-close-on-backdrop
    >
      <ValidationObserver ref="form">
        <form @submit.prevent="handleEditSubmit">
          <b-row>
            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="postcode_from"
                name="Start Postcode"
                rules="required"
              >
                <b-form-group label="Start Postcode">
                  <b-form-input
                    v-model="editForm.postcode_from"
                    type="number"
                  />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="postcode_to"
                name="End Postcode"
                rules="required"
              >
                <b-form-group label="End Postcode">
                  <b-form-input v-model="editForm.postcode_to" type="number" />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="selling_price"
                name="Unit Price"
                rules="required"
              >
                <b-form-group label="Unit Price">
                  <b-form-input
                    v-model="editForm.selling_price"
                    type="number"
                    :min="1"
                  />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="selling_price_cost"
                name="Unit Price Cost"
                rules="required"
              >
                <b-form-group label="Unit Price Cost">
                  <b-form-input
                    v-model="editForm.selling_price_cost"
                    type="number"
                  />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="double_charge"
                name="Double Charged"
                rules="required"
              >
                <b-form-group label="Double Charged">
                  <b-form-input
                    v-model="editForm.double_charge"
                    type="number"
                    :min="1"
                  />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="double_charge_cost"
                name="Double Charged Cost"
                rules="required"
              >
                <b-form-group label="Double Charged Cost">
                  <b-form-input
                    v-model="editForm.double_charge_cost"
                    type="number"
                  />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>

          <b-row class="align-items-center justify-content-center">
            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="convert_risk"
                name="Convert Risk"
                rules="required"
              >
                <b-form-group label="Convert Risk">
                  <b-form-input v-model="editForm.convert_risk" />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="zone"
                name="Zone"
                rules="required"
              >
                <b-form-group label="Zone">
                  <v-select
                    v-model="editForm.zone"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="zone"
                    :clearable="true"
                    :reduce="(zone) => zone.id"
                    label="zone_name"
                  >
                    <template #option="option">
                      <span>{{ option.zone_name }}</span>
                    </template>
                  </v-select>
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <div class="d-flex align-items-center justify-content-center my-1">
            <b-button type="submit" variant="primary"> Submit </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
    <!-- ADD ZONE MODAL -->
    <b-modal
      ref="zone"
      id="zone"
      title="Add Zone"
      hide-footer
      no-close-on-backdrop
    >
      <ValidationObserver ref="form">
        <form @submit.prevent="handleZoneSubmit">
          <b-row class="align-items-center justify-content-center">
            <b-col cols="12" lg="6">
              <ValidationProvider
                v-slot="{ errors }"
                vid="zone_name"
                name="Zone"
                rules="required"
              >
                <b-form-group label="Zone Name">
                  <b-form-input v-model="form.zone_name" />
                  <span
                    v-for="(error, index) in errors"
                    :key="index"
                    class="text-danger"
                  >
                    <small>{{ error }}</small>
                  </span>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <div class="d-flex align-items-center justify-content-center my-1">
            <b-button type="submit" variant="primary"> Submit </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </b-container>
</template>
<script>
import { VueGoodTable } from "vue-good-table";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    VueGoodTable,
    extend,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      restart: 0,
      isLoading: false,
      postcodeFrom: "",
      postcodeTo: "",
      selectedZone: 1,
      form: { convert_risk: 0 },
      editForm: {},
      zone: [],
      columns: [
        {
          label: "Sender Postcode",
          field: "postcode_from",
          tdClass: "text-center",
        },
        {
          label: "Recipient Postcode",
          field: "postcode_to",
          tdClass: "text-center",
        },
        {
          label: "Unit Price",
          field: "selling_price",
          tdClass: "text-center",
        },
        {
          label: "Unit Price Cost",
          field: "selling_price_cost",
          tdClass: "text-center",
        },
        {
          label: "Double Charged",
          field: "double_charge",
          tdClass: "text-center",
        },
        {
          label: "Double Charged Cost",
          field: "double_charge_cost",
          tdClass: "text-center",
        },
        {
          label: "Convert Risk",
          field: "convert_risk",
          tdClass: "text-center",
        },
        {
          label: "Actions",
          field: "actions",
          tdClass: "text-center",
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        searchTerm: "",
        column: [
          { label: "id", field: "id", sortable: false },
          {
            label: "postcode_from",
            field: "postcode_from",
            sortable: false,
            filterOptions: {
              enabled: true,
              filterValue: "01000", // initial populated value for this filter
              trigger: "enter",
            },
          },
          {
            label: "postcode_to",
            field: "postcode_to",
            sortable: false,
            filterOptions: {
              enabled: true,
              filterValue: "10000", // initial populated value for this filter
              trigger: "enter",
            },
          },
          { label: "selling_price", field: "selling_price", sortable: false },
          {
            label: "selling_price_cost",
            field: "selling_price_cost",
            sortable: false,
          },
          { label: "double_charge", field: "double_charge", sortable: false },
          {
            label: "double_charge_cost",
            field: "double_charge_cost",
            sortable: false,
          },
          {
            label: "convert_risk ",
            field: "convert_risk ",
            sortable: false,
          },
          {
            label: "zone",
            field: "zone",
            sortable: false,
          },
        ],
        sort: [
          {
            field: "id",
            type: "asc",
          },
        ],
        page: 1,
        perPage: 10,
        customParameter: [{ field: "zone", value: `1` }],
      },
      required,
    };
  },

  created() {
    this.getZone();
    this.loadItems();
  },

  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    isClick(id) {
      this.isLoading = true;
      this.serverParams.customParameter[0].value = id;
      this.loadItems();
    },

    extractPostcode() {
      if (this.postcodeFrom && this.postcodeTo) {
        this.serverParams.customParameter.splice(1, 2);
        this.serverParams.customParameter.push({
          field: "postcode_from",
          value: this.postcodeFrom,
        });
        this.serverParams.customParameter.push({
          field: "postcode_to",
          value: this.postcodeTo,
        });
      } else {
        this.serverParams.customParameter.splice(1, 2);
      }
      this.serverParams.page = 1;
      this.restart += 1;
      this.isLoading = true;
      this.loadItems();
    },

    loadItems() {
      this.$http
        .post(`get_distributor_price_checker`, this.serverParams)
        .then((response) => {
          this.totalRecords = response.data.totalRecords;
          this.rows = response.data.rows;
        });
      this.isLoading = false;
    },

    getZone() {
      this.$http.get(`zone`).then((response) => {
        this.zone = response.data.data;
      });
      this.isLoading = false;
    },

    handleSubmit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          this.$http
            .post("distributor_price_checker", this.form)
            .then((response) => {
              if (response.data.status === false) {
                this.$refs.form.setErrors(response.data.errors);
                this.isLoading = false;
                return;
              }
              this.$swal({
                title: "Success !",
                icon: "success",
                text: response.data.message,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.isLoading = false;
                  this.$refs["add"].hide();
                  this.form = {};
                  this.loadItems();
                }
              });
            });
        }
      });
    },

    handleZoneSubmit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          this.$http.post("zone", this.form).then((response) => {
            if (response.data.status === false) {
              this.$refs.form.setErrors(response.data.errors);
              this.isLoading = false;
              return;
            }
            this.$swal({
              title: "Success !",
              icon: "success",
              text: response.data.message,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.isConfirmed) {
                this.isLoading = false;
                this.$refs["zone"].hide();
                this.form = {};
                this.getZone();
              }
            });
          });
        }
      });
    },

    handleEditSubmit() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          this.$http
            .put(`distributor_price_checker/${this.editForm.id}`, this.editForm)
            .then((response) => {
              if (response.data.status === false) {
                this.$refs.form.setErrors(response.data.errors);
                this.isLoading = false;
                return;
              }
              this.$refs["edit"].hide();
              this.$swal({
                title: "Success !",
                icon: "success",
                text: response.data.message,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.isLoading = false;
                  this.$refs["edit"].hide();
                  this.loadItems();
                }
              });
            });
        }
      });
    },

    editPrice(row) {
      this.editForm = {};
      this.editForm = row;
      this.$refs["edit"].show();
    },

    async handleDelete(row) {
      this.$swal({
        title: "Are you sure?",
        text: `You are about to delete ${row.state} with postcode range from ${row.postcode_range_from} to ${row.postcode_range_to}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger mr-2",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await this.$http.delete(
            `distributor_price_checker/${row.id}`
          );
          if (response.data.status === true) {
            this.getPriceChecker();
            this.loadItems();
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: response.data.message,
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          } else {
            this.$swal({
              title: "Error !",
              icon: "warning",
              text: response.data.message,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/style.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
.fixed-width-header-container {
  width: 180px;
}
</style>
